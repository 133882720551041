import React, { Component } from "react"

interface PropsType {
  pageContext: { link: string }
}

export class Red extends Component<PropsType> {
  componentDidMount() {
    window.location.replace(this.props.pageContext.link)
    console.log(this.props.pageContext.link)
  }

  render() {
    return <div />
  }
}

export default Red
